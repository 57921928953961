.wrapper{
    width: 100%;
    height: 70px;
    background-color: #00C8C1;
    text-align: left;
}

.logo{
    display: inline-block;
    vertical-align: middle;
    height: 70px;
    width: 50%;
}

.img_logo_game{
    display: inline-block;
    vertical-align: middle;
    width: 33px;
    padding-left: 20px;
}

.game_name{

    display: inline-block;
    color:#fff;
    font-size: 24px;
    vertical-align: middle;
    line-height: 70px;
    padding-left: 10px;
}
.logo_firm{
    display: inline-block;
    width: 50%;
    text-align: right;
}
.img_logo_firm{
    display: inline-block;
    vertical-align: middle;
    width: 140px;
    padding-right: 20px;    
}
