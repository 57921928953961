.wrapper{
    padding: 20px;
}

.back{
    cursor: pointer;
    background-color: #00C8C1;
    color: #fff;
    width: 200px;
    border-radius: 20px;
    margin: auto;
}

.back img{
    width: 30px;
    vertical-align: middle;
}

.back span{
    padding-left: 10px;
    font-size: 20px;
    vertical-align: middle;
}

.text{
    text-align: center;
    padding: 0 30px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

/* .img_map{
    width: ;
} */