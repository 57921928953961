body{
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  font-family: 'Circe';
  font-weight: 700;
  font-size: 20px;

  background: url("/public/background.png");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.universal_btn_short{
  background-color: #00C8C1;
  color: #fff;
  width: 150px;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  margin: 20px auto 0 auto;
  text-align: center;
  cursor: pointer;
}

.unselectext {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}