.wrapper{
    padding: 20px;
}

.back{
    cursor: pointer;
    background-color: #00C8C1;
    color: #fff;
    width: 200px;
    border-radius: 20px;
    margin: auto;
}

.back img{
    width: 30px;
    vertical-align: middle;
}

.back span{
    padding-left: 10px;
    font-size: 20px;
    vertical-align: middle;
}

.text{
    margin-top: 40px;
    text-align: center;
    padding: 0 30px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

.input_box_answer{
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    height: 30px;
    width: 220px;
    padding: 5px 10px;
    margin-left: 10px;
    margin-top: 20px;
}