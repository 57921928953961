.wrapper{
    background:rgb(255, 255, 255);
    border: 2px solid  #00C8C1;
    border-radius:10px;
    border-top-left-radius: 0;
    width: 80%;
    max-width: 600px;
    margin: auto;
    height: auto;
    padding: 20px 20px;
    text-align: left;
}

.title{
    margin-top: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
}

.text_task{
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 0.7;
    margin: 5px 0 10px 10px;
}

.img_location{
    width: 20px;
    vertical-align: middle;
    margin-left: 5px;
}
.location{
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 0.7;
}


